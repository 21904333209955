.shopy {
  @import "../../node_modules/bootstrap/scss/functions";
  @import "../../node_modules/bootstrap/scss/variables";
  @import "../../node_modules/bootstrap/scss/mixins";
  @import "../../node_modules/bootstrap/scss/forms";
  @import "../../node_modules/bootstrap/scss/grid";
  @import "../../node_modules/bootstrap/scss/alert";
  @import "../../node_modules/bootstrap/scss/card";
  @import "../../node_modules/bootstrap/scss/bootstrap-utilities";
  @import "../../node_modules/bootstrap/scss/spinners";


  .shopy-availability {
    .amount {
      padding-right: 5px;
    }
  }

  hr {
    border-color: rgba(175, 175, 175, 0.34) !important;
  }

  &.loader-container {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 999999999;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #193976;

    .loader {
      width: 100vw;
      text-align: center
    }

    .message {
      font-size: 28px;
      font-weight: 600;
      line-height:1.6;
    }

    .spinner {
      width: 6rem;
      height: 6rem
    }
  }


  .mask {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.40);
    display: none;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    transition: ease-in 0.5s;

    .card-title, div {
      font-weight: bold;
      color: white !important;
    }
  }

  .product-card {
    figure {
      position: relative;
    }

    &:hover {
      .mask {
        display: flex;transition: ease-in 0.5s;
      }
    }
  }
}

.stop-scrolling {
  height: 100%;
  overflow: hidden;
}

.shopy.balance-banner {
  @media (max-width: 600px) {
    display: none;
  }
}
